import * as React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Seo, Alert } from '../components';
import { useTranslation } from '../hooks';
import { Content } from '../containers/Registration';

const Registration = ({ pageContext: { currentPath } }) => {
  const { t, te, locale } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t('SEO.REGISTRATION.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container>
        {te('REGISTRATION.INDEX.INFO') && (
          <Alert>{t('REGISTRATION.INDEX.INFO')}</Alert>
        )}
        <Content />
      </Container>
    </Layout>
  );
};

export default Registration;
